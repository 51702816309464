
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/* For use in src/lib/core/theming/_palette.scss */
$md-secpalette: (
    50 : #e0e9f1,
    100 : #b3c9dd,
    200 : #80a5c6,
    300 : #4d81af,
    400 : #26669e,
    500 : #004b8d,
    600 : #004485,
    700 : #003b7a,
    800 : #003370,
    900 : #00235d,
    A100 : #8eaeff,
    A200 : #5b8aff,
    A400 : #2865ff,
    A700 : #0e53ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-secpalette-accent: (
    50 : #fff9e9,
    100 : #fff1c8,
    200 : #ffe8a3,
    300 : #ffde7e,
    400 : #ffd762,
    500 : #ffd046,
    600 : #ffcb3f,
    700 : #ffc437,
    800 : #ffbe2f,
    900 : #ffb320,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffeecf,
    A700 : #ffe4b6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);







$login-demo-primary: mat.define-palette($md-secpalette);
$login-demo-accent: mat.define-palette($md-secpalette-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$login-demo-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$login-demo-theme: mat.define-light-theme((
  color: (
    primary: $login-demo-primary,
    accent: $login-demo-accent,
    warn: $login-demo-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($login-demo-theme);

